import { useReducer } from "react"
import storage from "local-storage-fallback"

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_DARK_MODE":
      storage.setItem("isDark", !state.isDark)
      return {
        isDark: !state.isDark,
        sunrise: state.sunrise,
        sunset: state.sunset,
      }
    case "SET_DARK_MODE": {
      storage.setItem("isDark", action.payload.isDark)
      return {
        isDark: action.payload.isDark,
        sunrise: action.payload.sunrise,
        sunset: action.payload.sunset,
      }
    }
    default: {
      return state
    }
  }
}

const useGlobalState = () => {
  const [state, dispatch] = useReducer(reducer, {
    isDark: storage.getItem("isDark")
      ? JSON.parse(storage.getItem("isDark"))
      : false,
    sunrise: null,
    sunset: null,
  })

  return { state, dispatch }
}

export default useGlobalState
