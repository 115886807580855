export const theme = {
  light: {
    font: "#1E1C26",
    textFont: "#1E1C26",
    background: "#F2F2F2",
  },
  dark: {
    font: "#F2C777",
    textFont: "#F2F2F2",
    background: "#1E1C26",
    accent: "#BF544B",
  },
}
